import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import useGaTracking from 'hooks/use-ga-tracking';
import Heading from 'components/heading';

const CognitoForm = ({ dataKey, dataForm, title }) => {
  const cognitoScriptSrc = 'https://www.cognitoforms.com/f/seamless.js';
  const formRef = useRef(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.setAttribute('src', cognitoScriptSrc);
    script.setAttribute('data-key', dataKey);
    script.setAttribute('data-form', dataForm);
    script.setAttribute('type', 'text/javascript');
    script.async = true;

    if (formRef.current) {
      formRef.current.appendChild(script);
    }

    const setupEventHandlers = () => {
      if (window.Cognito) {
        window.Cognito.on('beforeSubmit', event => {
          const { entry, hasErrors } = event.data;

          if (hasErrors) {
            return;
          }

          const formData = {
            Name: entry?.Name,
            Email: entry?.Email,
            PhoneNumber: entry?.PhoneNumber
          };

          if (window.dataLayer) {
            window.dataLayer.push({
              event: 'formSubmit',
              formTitle: title,
              formData
            });
          }

          useGaTracking('CognitoForm', 'Form Submit', title);
        });
      }
    };

    const observer = new MutationObserver(() => {
      if (window.Cognito) {
        setupEventHandlers();
        observer.disconnect();
      }
    });

    observer.observe(formRef.current, { childList: true, subtree: true });

    return () => {
      observer.disconnect();
      if (formRef.current?.contains(script)) {
        formRef.current.removeChild(script);
      }
    };
  }, [dataKey, dataForm, title]);

  return (
    <div className="cognito-form">
      <Heading
        level={2}
        theme={Heading.themes.h2}
        className="cognito-form__title"
      >
        {title}
      </Heading>
      <div ref={formRef} className="cognito-form__container"></div>
    </div>
  );
};

CognitoForm.propTypes = {
  dataKey: PropTypes.string.isRequired,
  dataForm: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default CognitoForm;
